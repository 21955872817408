/*
 * @Date: 2024-09-03 10:35:01
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-10-15 15:28:15
 * @FilePath: \zyt-mobile-frontend\src\config\index.js
 */
export default {
  // apiUrl: 'http://localhost:3000/api'
  // 浙影通二期网站首页地址（正式环境时删除debug=true）
  homeUrl: process.env.NODE_ENV==='development'?'http://192.168.3.5:3344':"https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2002427148/reserved/index.html",
  // homeUrl: "https://mapi.zjzwfw.gov.cn/web/mgop/gov-open/zj/2002427148/reserved/index.html",
  appid: "2002427148",
  isDev: process.env.NODE_ENV === 'development',
  // 观看放映人数范围取值
  audienceRange: [
    { text: '11-20人', value: '11-20' },
    { text: '21-50人', value: '21-50' },
    { text: '51-100人', value: '51-100' },
    { text: '100-200人', value: '100-200' },
    { text: '200人以上', value: '200以上' },
  ],
  playPlace: [
    { text: '室内放映', value: 1 },
    { text: '室外放映', value: 2 },
  ],
  Times : [
    {
      name:'上午',
      value:'morning'
    },
      {
      name:'下午',
      value:'afternoon'
    },
      {
      name:'晚上',
      value:'evening'
    }
    // 上午: 'morning',
    // 下午: 'afternoon',
    // 晚上: 'evening',
  ]
}
